

import Typed from 'react-typed';
import { useNavigate } from 'react-router-dom';
import('../Styles/home.css')
const Home=()=>{
  const navigate=useNavigate();
    return(
        <>
            <section className="one">
        <div className="leftmain">
          <img className="prof" src={require('../Assets/profile.jpg')} alt='' />
          
        </div>
        {/* <!-- <div className="leftmain"><img className="prof" src="./images/IMG_20230718_230744-removebg.png"></div> --> */}
        <div className="rightmain">
          Hey there!
          <div>My name is</div>
          <div className="name">Kedar Shenoy</div>
          <div>I am a Passionate</div>
          <div>Web Developer</div>

          <div className="stacks"><Typed
                strings={[
                  "HTML ",
                  "CSS ",
                  "Bootstrap",
                  "Tailwind",
                  "JavaScript",
                  "React JS",
                  "Typescript",
                  "Node JS",
                  "ExpressJS",
                  "next JS",
                  "MongoDB",
                  "Mongoose",
                  "Prisma",
                  "Postgress",
                  "API",
                  "php",
                  "SQL",]}
                    typeSpeed={40}
                    backSpeed={50}
                    cursorChar='❕'
                    // showCursor={false}
                    // attr="placeholder"
                    loop >
                    <span id="element"> </span>
                </Typed> 
                </div>
        <button onClick={()=>{navigate('/about')}}>Hire Me</button>

          
          
        </div>
        {/* {typed()} */}
        
      </section>
        {/* <div className='hirebtns'> */}
        {/* <button onClick={()=>{navigate('/about')}}>Hire Me</button> */}
        {/* <button>Hover Me</button> */}

        {/* <div className='hirebtnsbox' style={{ textAlign:'center',marginTop:"20px"}}>Hire Me!</div> */}
        {/* <div className='hirebtnsbox' style={{ textAlign:'center',marginTop:"20px"}}>Career Insights</div> */}
        {/* </div> */}


     
   
      
        </>
    )

}

export default Home;