import pdf from '../Assets/Kedar.pdf'
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import('../Styles/Nav.css');
function show() {
    // let element=document.getElementById("cv");
    window.open(pdf)
  }
const Nav=()=>{
  const navigate=useNavigate()
  const [isOpen, setIsOpen] = useState(false);


    const toggleNavbar = () => {
        setIsOpen(!isOpen);
    };
  
    return(
        <>
        <nav>
        <div className="left " onClick={()=>navigate('/home')}>Kedar's Portfolio</div>
        <div className="right">
        <div className='dropdown'> <img src={require('../Assets/images/dropdown.png') } alt='.' className='dropdownicon' onClick={()=>toggleNavbar()}></img></div>
          {/* <img src={require('../Assets/images/dropdown.png')} alt='▤' className='dropdownicon'></img> */}
          <ul style={{ display: (window.innerWidth > 480) ? 'flex' : (isOpen ? 'block' : 'none') }} >
            <li><Link to="/home" onClick={()=>toggleNavbar()}>Home</Link></li>
            <li><Link to="/about" onClick={()=>toggleNavbar()}>About</Link></li>
            <li onClick={()=>show()}>CV</li>
            <li><Link to="/projects" onClick={()=>toggleNavbar()}>Projects</Link></li>
            <li><Link to="/certificates" onClick={()=>toggleNavbar()}>Certificates</Link></li>
            <li><Link to="/contact" onClick={()=>toggleNavbar()}>Contact me</Link></li>
          </ul>
        </div>
      </nav>
        </>
    )

}

export default Nav;