const open=()=>{}
const CV=()=>{
    return(
       <> 
       <h1>CV component</h1>
        {
           open()
        }
        </>
    )

}

export default CV;