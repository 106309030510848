import { useState } from 'react';
import ('../Styles/certificate.css')
const Certificate=()=>{

  const [collapsed1, setCollapsed1] = useState(true);
  const [collapsed2, setCollapsed2] = useState(true);
  const [collapsed3, setCollapsed3] = useState(true);
  const [collapsed4, setCollapsed4] = useState(true);
  const [collapsed5, setCollapsed5] = useState(true);
  const [collapsed6, setCollapsed6] = useState(true);
  const [collapsed7, setCollapsed7] = useState(true);
  const [collapsed8, setCollapsed8] = useState(true);


  // Function to toggle the collapsed state
  const toggleCollapse = (n,m) => {
    n(!m);
  }

    return(
        <>
          <div className="container">
             <div className="headingcertif">Certificates</div>

<div className="firstcertificate row">
  <div className="certiname col-lg-5">
    <img
      src={require("../Assets/certificateimg/edurekamian.jpg")} alt=""
      // max-width="50vw"
      height="220vh" 
      // max-width="40vw"
    //   onclick="opencertificate('main')"
    />
  </div>
  <div className="certidesc col-lg-6">
    <div className="titler">
      Completion of the Full Stack Internship Program
    </div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed1,collapsed1)}>
        {collapsed1 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed1 && (
    
    `This certificate is awarded to me in recognition of successful
    completion of the Full Stack Internship Program by Edureka, "During my
    journey in the Full Stack Internship Program by Edureka, I immersed
    myself in a comprehensive and hands-on learning experience. Throughout
    the program, I gained proficiency in various web development
    technologies, including HTML, CSS, JavaScript, React, Bootstrap,
    MongoDB, Express.js, and Node.js.`
    )}
  </div>
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>
{/* //////////////////////// */}
<div className="firstcertificate row">
<div className="certidesc col-lg-6">
    <div className="titlel">
    Node Certification
    </div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed2,collapsed2)}>
        {collapsed2 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed2 && (
    `This certificate recognizes successfully completing the Node Basic Certification exam by HackerEarth in December 2023. Through comprehensive learning and practical assessments, I gained proficiency in essential Node.js concepts including environment setup, module management, asynchronous programming, file system operations, HTTP server creation, and database interaction. This certification validates my foundational skills in Node.js development and signifies my dedication to mastering key concepts in the field.`)}
    </div>
  <div className="certiname col-lg-5">
    <img
      src={require("../Assets/certificateimg/Hackerearth1.jpg")} alt=""
      // max-width="50vw"
      height="220vh" 
      // max-width="40vw"
    //   onclick="opencertificate('main')"
    />
  </div>
  
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>
{/* //////////////////////// */}

<div className="firstcertificate row">
  
  <div className="certiname col-lg-5">
  <a
      href="https://www.credly.com/badges/c3b67d70-d753-49ee-b0b7-80f476f450e9/public_url"
    >
  <img
      src={require("../Assets/certificateimg/microsoft.jpg")} alt=""
      height="220vh" 
      width="100%"

      // height="300px"
    //   onclick="opencertificate('main')"
    /></a>
    {/* <a
      href="https://www.credly.com/badges/c3b67d70-d753-49ee-b0b7-80f476f450e9/public_url"
      target="_blank"
    >
      // <img height="300px" src="./certificateimg/microsoft.jpg" />
    </a> */}
  </div>
  <div className="certidesc col-lg-6 my-2">
    <div className="titler">
      MTA: Introduction to Programming Using JavaScript - Certified 2022
    </div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed3,collapsed3)}>
        {collapsed3 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed3 && (
    `Having successfully passed the MTA exam, I have earned the Microsoft
    Technology Associate certification, validating my fundamental knowledge
    and understanding of core technology concepts. This achievement marks a
    significant milestone in my career, as it demonstrates my commitment to
    staying current with industry-standard technologies and showcases my
    readiness to take on new challenges in the IT landscape.`)}
  </div>
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>
{/* //////////////////////// */}


<div className="firstcertificate row">
<div className="certidesc col-lg-6 my-2">
    <div className="titlel">B-tech Course Complition Certificate</div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed4,collapsed4)}>
        {collapsed4 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed4 && (`
    The journey through B.Tech was both challenging and rewarding. It tested
    my analytical and problem-solving skills, encouraging me to think
    critically and creatively. I encountered complex projects, group
    assignments, and practical experiments that pushed me beyond my comfort
    zone and shaped me into a resilient and adaptable individual.Receiving
    this B.Tech course completion certificate is a testament to my passion
    for technology and my determination to excel.`)}
  </div>
  <div className="certiname col-lg-5">
  <img
      src={require("../Assets/certificateimg/course.jpg")} alt=""
      height="220vh" 
      
      // height="300px"
    //   onclick="opencertificate('main')"
    />
  </div>

  
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>

{/* //////////////////////// */}

<div className="firstcertificate row">
  

  <div className="certiname col-lg-5">
  <img
      src={require("../Assets/certificateimg/mod1.jpg")} alt=""
      height="220vh" 
      
      // height="300px"
    //   onclick="opencertificate('main')"
    />
   
  </div>
  <div className="certidesc col-lg-6 my-2">
    <div className="titler">Module-1 Complition Edureka</div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed5,collapsed5)}>
        {collapsed5 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed5 && (`
    Throughout this module, I embarked on an enriching learning journey to
    understand the building blocks of web development. I acquired a
    comprehensive understanding of HTML, the fundamental markup language
    that forms the backbone of web pages. CSS empowered me to apply styles
    and layouts to HTML elements, making them attractive and visually
    engaging. Additionally, I explored the power of Bootstrap, a popular
    front-end framework that accelerated my development process by providing
    a wide range of pre-designed components and responsive layout utilities.`)}
  </div>
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>

{/* //////////////////////// */}

<div className="firstcertificate row">
<div className="certidesc col-lg-6 my-2">
    <div className="titler">Module-2 Complition Edureka</div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed6,collapsed6)}>
        {collapsed6 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed6 && (`
    During this comprehensive module, I delved into the world of JavaScript,
    a versatile and powerful scripting language that plays a pivotal role in
    modern web development. I gained a deep understanding of JavaScript
    fundamentals, including variables, data types, functions, and control
    flow, which allowed me to write dynamic and interactive code. The
    module's emphasis on Document Object Model (DOM) manipulation enabled me
    to create dynamic web pages that respond to user interactions. I learned
    to harness the power of JavaScript to create engaging user experiences
    and build interactive web applications.`)}
  </div>
  <div className="certiname col-lg-5">
  <img
      src={require("../Assets/certificateimg/mod2.jpg")} alt=""
      height="220vh" 
      
      // height="300px"
    //   onclick="opencertificate('main')"
    />
    
  </div>
  
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>
{/* //////////////////////// */}


<div className="firstcertificate row">
  

  <div className="certiname col-lg-5">
  <img
      src={require("../Assets/certificateimg/mod3.jpg")} alt=""
      height="220vh" 
     
      // height="300px"
    //   onclick="opencertificate('main')"
    />
    
  </div>
  <div className="certidesc col-lg-6 my-2">
    <div className="titlel">Module-3 Complition Edureka</div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed7,collapsed7)}>
        {collapsed7 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed7 && (`
    Throughout this comprehensive module, I immersed myself in the world of
    server-side development with Node.js, a powerful runtime environment for
    JavaScript. I gained hands-on experience in building web applications
    with the Express.js framework, which provided me with the tools to
    create robust APIs and routes. Additionally, I explored the capabilities
    of MongoDB, a NoSQL database, and learned to manage data efficiently in
    web applications.`)}
  </div>
</div>
<div className='rulerdiv'><hr className="ruler"></hr></div>
{/* //////////////////////// */}


<div className="firstcertificate row">
<div className="certidesc col-lg-6 my-2">
    <div className="titler">Module-4 Complition Edureka</div>
    <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer',textAlign:'center'}} onClick={()=>toggleCollapse(setCollapsed8,collapsed8)}>
        {collapsed8 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed8 && (`
    Throughout this intensive module, I delved deep into the world of React,
    a popular front-end library for building modern web applications. I
    gained a comprehensive understanding of React components, state
    management, and the virtual DOM, which allowed me to create efficient
    and reusable user interface elements.`)}
  </div>
  <div className="certiname col-lg-5">
  <img
      src={require("../Assets/certificateimg/mod4.jpg")} alt=""
      height="220vh" 
      
      // height="300px"
    //   onclick="opencertificate('main')"
    />
    
  </div>
  
</div>
</div>
</>
    )

}

export default Certificate;