import { useState } from 'react';
import('../Styles/project.css')
const Projects=()=>{
  
    // Define state to track whether the div is collapsed or not
    const [collapsed1, setCollapsed1] = useState(true);
    const [collapsed2, setCollapsed2] = useState(true);
    const [collapsed3, setCollapsed3] = useState(true);
    const [collapsed4, setCollapsed4] = useState(true);
    const [collapsed5, setCollapsed5] = useState(true);

    

  
    // Function to toggle the collapsed state
    const toggleCollapse = (n,m) => {
      n(!m);
    }

    return(
        <>
        <div className="projectmainheading">Projects</div>
    <section className="firstproject">
      <div className="prtojectheading"><a className="link"href="https://aksfoodordering.onrender.com/"
      title="⚠ This website is hosted on a free instance. Please be aware that there might be occasional latency, slower loading times, or server crashes. We apologize for any inconvenience caused. Thank you for your understanding and patience!">A food ordering web application ↝</a></div>
      {/* <iframe src="./bootstrap/home.html" width="70%" height="60%"></iframe> */}
      <video className='video' src={require('../Assets/zomato.mp4')}  autoPlay muted playsInline loop></video>
      <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer'}} onClick={()=>toggleCollapse(setCollapsed1,collapsed1)}>
        {collapsed1 ? 'View Discription ▼': <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed1 && (
      <div className="prtojectdesc">
      I am thrilled to present my latest project, a food ordering website built from scratch using the MERN (MongoDB, Express.js, React.js, Node.js) stack. This project not only demonstrates my proficiency in full-stack development but also showcases my ability to create intuitive user interfaces and integrate essential functionalities.
      <div className="sidepointsprojm">Technologies Used:</div>
        <div className="sidepointsproj">
          Front-End:
          <span className="valuesproj">React.js, Axios for API requests, carousels and tabs</span>
        </div>
        <div className="sidepointsproj">
          Back-End: <span className="valuesproj">Express.js and Node.js for building robust APIs, MongoDB.</span>
        </div>
        <div className="sidepointsproj">
        Deployment: <span className="valuesproj">Hosted the website on Render</span>
        </div>

        <div className="sidepointsprojm">Key Features:</div>
        <div className="sidepointsproj">
        User-Friendly Interface:
          <span className="valuesproj">Implemented intuitive carousels and tabs, allowing users to easily navigate through various food items and sections of the website.</span>
        </div>
        <div className="sidepointsproj">
        Efficient Backend: <span className="valuesproj">Developed a secure backend API handling order requests, managing user data, and storing food item details in a MongoDB database. Ensured data integrity and security using Mongoose.</span>
        </div>
        <div className="sidepointsproj">
        Deployment on Render: <span className="valuesproj">Hosted the website on Render, ensuring seamless performance and accessibility to users on the internet.</span>
        </div>
        <div className="sidepointsprojm">Ongoing Enhancements:</div>
        <div className="sidepointsproj">
        Payment Gateway Integration:
          <span className="valuesproj"> Currently working on integrating a payment gateway (e.g., Stripe) to facilitate secure online payments, ensuring a seamless and secure transaction process for users.</span>
        </div>
        <div className="sidepointsproj">
        Live Order Tracking:<span className="valuesproj">Implementing live order tracking functionality, allowing users to monitor their orders in real time, enhancing their overall experience and satisfaction.</span>
        </div>
        <div className="sidepointsprojm">Key Takeaways:</div>
        <div className="sidepointsproj">
          <span className="valuesproj">This project allowed me to enhance my skills in both frontend and backend development. I honed my abilities in creating interactive and visually appealing user interfaces, managing backend APIs, and ensuring a smooth deployment process.</span>
        </div>
      </div>)}
    </section>


    <section className="firstproject" > {/*  style="margin-bottom: 100px;"*/}
      <div className="prtojectheading"><a className="link"href="https://trycodeonline.onrender.com" 
      title="⚠ This website is hosted on a free instance. Please be aware that there might be occasional latency, slower loading times, or server crashes. We apologize for any inconvenience caused. Thank you for your understanding and patience!">tryCode ↝</a></div>
      {/* <iframe title="leetcode" src={require("../bootstrap/home.html")} width="70%" height="60%"></iframe> */}
      <video className='video' src={require('../Assets/leetcode.mp4')} autoPlay muted playsInline loop></video>
      <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer'}} onClick={()=>toggleCollapse(setCollapsed2,collapsed2)}>
        {collapsed2 ? 'View Discription ▼' : <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed2 && (

      <div className="prtojectdesc">
      I am excited to share my ongoing project, TryCode, a LeetCode clone that I'm currently developing using the MERN (MongoDB, Express.js, React.js, Node.js) stack. This project is a testament to my passion for coding and my commitment to building robust, interactive web applications.
       <div className="sidepointsprojm">Technologies Used:</div>
        <div className="sidepointsproj">
          Front-End:
          <span className="valuesproj"> React.js, React Tabs.</span>
        </div>
        <div className="sidepointsproj">
          Back-End: <span className="valuesproj"> Express.js and Node.js for building custom APIs, MongoDB.</span>
        </div>
        <div className="sidepointsproj">
        Validation:<span className="valuesproj">Implementing code validation and submission using RabbitMQ.</span>
        </div>
       <div className="sidepointsprojm">Key Features:</div> 
        <div className="sidepointsproj">
        Interactive User Interface:
          <span className="valuesproj">Designed an intuitive user interface using React Tabs, allowing users to seamlessly navigate between different coding problems and categories.</span>
        </div>
        <div className="sidepointsproj">
        Custom APIs: <span className="valuesproj">Developing custom APIs to handle user authentication, problem submissions, and code validation. These APIs will be integrated with the frontend, ensuring a seamless user experience.</span>
        </div>
        <div className="sidepointsproj">
        Code Validation and Submission: <span className="valuesproj">Implementing code validation and submission using RabbitMQ. This functionality will validate code submissions for correctness and efficiency, providing valuable feedback to users.</span>
        </div>
        <div className="sidepointsprojm">Upcoming Enhancements:</div>
        <div className="sidepointsproj">
        RabbitMQ Integration:
          <span className="valuesproj">Currently working on integrating RabbitMQ for efficient and asynchronous code validation and submission, enhancing the overall performance of the application.</span>
        </div>
        <div className="sidepointsproj">
        Docker Containerization: <span className="valuesproj">Planning to Dockerize the application for easy deployment and scaling, ensuring consistency across different environments.</span>
        </div>
        
      </div>)}
    </section>


    <section className="firstproject" > {/*  style="margin-bottom: 100px;"*/}
      <div className="prtojectheading"><a className="link"href="https://trycodeonline.onrender.com" 
       >Coin Collection</a></div>
      {/* <iframe title="leetcode" src={require("../bootstrap/home.html")} width="70%" height="60%"></iframe> */}
      <video className='video' src={require('../Assets/coincollection.mp4')} autoPlay muted playsInline loop></video>
      <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer'}} onClick={()=>toggleCollapse(setCollapsed3,collapsed3)}>
        {collapsed3 ? 'View Discription ▼' : <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed3 && (

      <div className="prtojectdesc">
     Introducing CoinTracker, your comprehensive coin collection companion built on the powerful MERN stack (MongoDB, Express.js, React, Node.js). Seamlessly manage and showcase your prized coin collection with this user-friendly app designed for both novice enthusiasts and seasoned collectors.
 <div className="sidepointsprojm">Technologies Used:</div>
        <div className="sidepointsproj">
          Front-End:
          <span className="valuesproj"> HTML, CSS, and React.js.
</span>
        </div>
        <div className="sidepointsproj">
          Back-End: <span className="valuesproj"> Node.js and Express.js</span>.</div>
          <div className="sidepointsproj">
        Database <span className="valuesproj">MongoDB
</span>
        </div>
       <div className="sidepointsprojm">Key Features:</div> 
        <div className="sidepointsproj">
        Interactive User Interface:
          <span className="valuesproj">Empowered by MongoDB, users can efficiently manage and categorize their coin collections, providing a comprehensive overview of their numismatic treasures.
</span>
        </div>
        
        
        
        
      </div>)}
    </section>

    <section className="firstproject" > {/*  style="margin-bottom: 100px;"*/}
      <div className="prtojectheading"><a className="link"href="/" 
       >Paytm Clone</a></div>
      {/* <iframe title="leetcode" src={require("../bootstrap/home.html")} width="70%" height="60%"></iframe> */}
      <img className='video' src={require('../Assets/Paytm.jpg')} alt='.' style={{marginBottom:'10px'}}></img>
      <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer'}} onClick={()=>toggleCollapse(setCollapsed4,collapsed4)}>
        {collapsed4 ? 'View Discription ▼' : <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed4 && (

      <div className="prtojectdesc">
     Paytm Clone Built using Typescript,NextJs technologies.(Under development) 
 <div className="sidepointsprojm">Technologies Used:</div>
        <div className="sidepointsproj">
          Front-End:
          <span className="valuesproj"> HTML, CSS, React.js, Typescript </span>
        </div>
        <div className="sidepointsproj">
          Back-End: <span className="valuesproj"> NextJs</span>.</div>
          <div className="sidepointsproj">
        Database <span className="valuesproj">Postgress</span>
        </div>
       {/* <div className="sidepointsprojm">Key Features:</div>  */}
        {/* <div className="sidepointsproj">
        Interactive User Interface:
          <span className="valuesproj">Empowered by MongoDB, users can efficiently manage and categorize their coin collections, providing a comprehensive overview of their numismatic treasures.
          </span>
        </div> */}
        
        
        
        
      </div>)}
    </section>
     



    <section className="firstproject"  style={{marginBottom:'20px'}}> {/*  style="margin-bottom: 100px;"*/}
      <div className="prtojectheading" ><a className="link"href="https://github.com/kedarshenoy/DiabetesPrediction-">Diabetes Detection ↝</a></div>
      {/* <iframe src="./emp/tryhome.html" width="70%" height="60%"></iframe> */}
      <video className='video' src={require('../Assets/diabeets.mp4')}  autoPlay muted playsInline loop></video>
      <div style={{ color:'whitesmoke',opacity:'50%',cursor:'pointer'}} onClick={()=>toggleCollapse(setCollapsed5,collapsed5)}>
        {collapsed5 ? 'View Discription ▼' : <div style={{}}>Close ▲</div>}
      </div>
      {!collapsed5 && (
      <div className="prtojectdesc" style={{marginBottom:'20px'}}>
      I am excited to showcase my project focused on diabetes detection using machine learning algorithms. This project seamlessly integrates frontend technologies with backend development and machine learning, creating an interactive and user-friendly web application.
        <div className="sidepointsprojm">Technologies Used:</div>
        <div className="sidepointsproj">
          Front-End:
          <span className="valuesproj"> HTML, CSS, Bootstrap</span>
        </div>
        <div className="sidepointsproj">
          Back-End: <span className="valuesproj"> Flask, a lightweight Python web framework, for server-side logic and API development.</span>
        </div>
        <div className="sidepointsproj">
        Machine Learning Algorithms:<span className="valuesproj">  Random Forest (RF) and Support Vector Machine (SVM) for accurate diabetes prediction.</span>
        </div>
        <div className="sidepointsproj">
          Database: <span className="valuesproj">SQL for storing user data securely and managing authentication and authorization processes.</span>
        </div>



        <div className="sidepointsprojm">Key Features:</div>
        <div className="sidepointsproj">
        Diabetes Prediction:
          <span className="valuesproj"> Utilized Random Forest and Support Vector Machine algorithms to build accurate prediction models based on user inputs, allowing users to assess their risk of diabetes.</span>
        </div>
        <div className="sidepointsproj">
        User-Friendly Interface:  <span className="valuesproj">Designed an intuitive and visually appealing frontend using HTML, CSS, and Bootstrap, ensuring a seamless user experience across devices.</span>
        </div>


        <div className="sidepointsprojm">How It Works::</div>
        <div className="sidepointsproj">
        User Inputs
          <span className="valuesproj">Users input their relevant health data such as glucose levels, BMI, and blood pressure into the web application.</span>
        </div>
        <div className="sidepointsproj">
        Machine Learning Prediction:   <span className="valuesproj"> The application processes this data through RF and SVM algorithms, providing an accurate prediction regarding the likelihood of diabetes.</span>
        </div>
        <div className="sidepointsproj">
        Result Display   <span className="valuesproj"> Users receive the prediction results displayed on the website, helping them understand their risk and take appropriate actions.</span>
        </div>
      </div>)}
    </section>
        </>
    )

}

export default Projects;